export function parseContent(string, altCity, stateAbbreviation) {
  if (string && altCity) {
    let newString = string.replace(/{{city}}/g, altCity ? altCity : '')

    //if stateAbbreviation var is passed, look for & replace that in content
    if(stateAbbreviation) {
      newString = newString.replace(/{{stateAbbreviation}}/g, stateAbbreviation ? stateAbbreviation : '')
    }
    const secondReplacement = newString.replace(
      /{{citySlug}}/g,
      (altCity ? altCity : '')
        .replace(/\./g, '')
        .toLowerCase()
        .replace(/\s/g, '-') + '-ca'
    )

    return secondReplacement
  } else {
      return string
  }
}