import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Menu } from '@headlessui/react'
import { ChevronRight } from 'react-feather'

function MenuItemDropdown({ isActivePage, item, label, source, headerTransparent }) {

  const [itemOpen, setItemOpen] = useState(false)
  const [subMenuChildrenOpen, setSubMenuChildrenOpen] = useState(false)

  const DropdownMenuItem = ({ item, type }) => {
    let itemId = item.id
    let title = item.title
    let slug = item.slug

    let label = item.title


     if (source && source == 'mobilemenu') {
      return (
        <>
        {item && item.areasServiceMenuItems && item.areasServiceService ?
        <span><span className='flex items-center justify-between  block py-2 px-2 hover:underline'>             
        <Link to={`/${slug}`} title={title ? title : label} className=" hover:text-brand-600" ><span className="block">{label}</span></Link>
        <button type='button'  className="block p-3 -m-2 group transition duration-150 ease-in" onClick={()=>setSubMenuChildrenOpen(!subMenuChildrenOpen)} style={{transform: `rotate(${subMenuChildrenOpen ? '90' : '0'}deg)`}}><ChevronRight  className={`group-hover:bg-brand-600 group-hover:text-white  w-7 h-7 p-1 bg-brand-100 text-brand-600 rounded-lg font-semibold`}/></button>
        </span>
          <span className={`${!subMenuChildrenOpen && 'hidden'} pl-8 block px-4 py-2 -mt-4 font-normal   focus:outline-none focus:bg-gray-50  transition-colors duration-150 ease-in`}>

            {item.areasServiceMenuItems.map((locationItemInfo, i) => ( 
              <Link key={i} className="text-gray-700 hover:text-brand-600  w-full block py-1" to={`/${locationItemInfo.slug}-${locationItemInfo.stateAbbreviation.toLowerCase()}-${item.areasServiceService.slug}`} title={title ? locationItemInfo.location +" "+ title : label}>
                {locationItemInfo.location + ", " + locationItemInfo.stateAbbreviation}  
              </Link>

            ))}
          </span></span> : <Link
          to={`/${slug && slug.length < 2 ? '' : slug}`}
          className={`block py-2 px-2 hover:underline`}
        >
          {label}
        </Link>}
      
          </>


        
       
      )
    }
    return (
      <>
        <Menu.Item className="block p-4 font-normal hover:bg-gray-50 focus:outline-none focus:bg-gray-50  text-gray-700 hover:text-brand-600 transition-colors duration-150 ease-in">
 
            <>
              {item && item.areasServiceMenuItems && item.areasServiceService ?
              <span><span className='flex items-center justify-between   p-4 pb-2 font-normal focus:outline-none focus:bg-gray-50  text-gray-700 transition-colors duration-150 ease-in'>             
              <Link to={`/${slug}`} title={title ? title : label} className=" hover:text-brand-600" ><span className="font-semibold block">{label}</span></Link>
              <button type='button'  className="block p-3 -m-2 group transition duration-150 ease-in" onClick={()=>setSubMenuChildrenOpen(!subMenuChildrenOpen)} style={{transform: `rotate(${subMenuChildrenOpen ? '90' : '0'}deg)`}}><ChevronRight  className={`group-hover:bg-brand-600 group-hover:text-white  w-7 h-7 p-1 bg-brand-100 text-brand-600 rounded-lg font-semibold`}/></button>
              </span>
                <span className={`${!subMenuChildrenOpen && 'hidden'} pl-8 block px-4 py-2 -mt-4 font-normal   focus:outline-none focus:bg-gray-50  transition-colors duration-150 ease-in`}>

                  {item.areasServiceMenuItems.map((locationItemInfo, i) => ( 
                    <Link key={i} className="text-gray-700 hover:text-brand-600  w-full block py-1" to={`/${locationItemInfo.slug}-${locationItemInfo.stateAbbreviation.toLowerCase()}-${item.areasServiceService.slug}`} title={title ? locationItemInfo.location +" "+ title : label}>
                      {locationItemInfo.location + ", " + locationItemInfo.stateAbbreviation}  
                    </Link>

                  ))}
                </span></span> : <Link to={`/${slug}`} title={title ? title : label} className="block p-4 font-normal hover:bg-gray-50 focus:outline-none focus:bg-gray-50  text-gray-700 hover:text-brand-600 transition-colors duration-150 ease-in"><span className="font-semibold block">{label}</span></Link>}
            
                </>
      

        </Menu.Item>


      </>
    )
  }

  if (source && source == 'mobilemenu') {
    return (
      <div className="py-4 px-8  border-b border-gray-300">
        <span className="font-semibold mb-2 block">{label}</span>
        {item.subitems.map((item, i) => (
          <DropdownMenuItem key={i} item={item} type={item.type} />
        ))}
      </div>
    )
  }
  return (
    <div
      className={`${headerTransparent
          ? 'text-white hover:text-brand-200'
          : 'text-gray-800 hover:text-brand-600'
        } relative inline-block text-left font-semibold text-base transition-colors duration-150 ease-in`}
      data-type={item.dropdownonesection}
    >
      <Menu>
        <Menu.Button
          className={`${isActivePage ? 'border-brand-500 text-brand-600' : ''} flex items-center font-semibold py-8 border-b-4 border-transparent  focus:outline-none button-active-bhvr ${headerTransparent
              ? 'hover:border-brand-300'
              : 'hover:border-brand-600'
            }`}
        >
          {({ open }) => (
            <>
              <span>{label}</span>
              <svg
                className={`w-6 h-6 ml-1 opacity-50 rotate-0  duration-75 transition-transform ease-in ${open ? 'transform rotate-180' : ''
                  }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </>
          )}
        </Menu.Button>

        <Menu.Items
          className="absolute left-0   origin-top-left bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
          style={{ width: '20rem' }}
        >
          {item.subitems.map((item, i) => (
            <>
              {' '}
              <DropdownMenuItem key={i} item={item} type={item.type} />{' '}
            </>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  )
}

export default MenuItemDropdown
