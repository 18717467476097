import React from "react";
import { Spinner } from "./Spinner";
import { CogIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";
  
  const sizeClassnames = {
    xl: "py-2 px-5 text-base rounded-full font-semibold md:text-lg",
    big: "py-2 px-5 text-sm rounded-full font-semibold text-sm md:text-base",
    small: "px-2 py-1 text-xs rounded-full font-semibold md:text-sm", 
  };
  
  const colorClassnames = {
    primary:
      "text-white bg-brand-500 hover:bg-brand-600 disabled:text-brand-600 disabled:bg-brand-600 focus:ring-4 focus:ring-brand-300",
    secondary:
      "bg-white border border-gray-300  font-medium text-gray-700 hover:bg-gray-50 disabled:text-brand-300 focus:ring-4 focus:ring-gray-100",
    red:
    "text-white bg-red-500 hover:bg-red-600 disabled:text-red-600 disabled:bg-red-600 focus:ring-4 focus:ring-red-300",
    light:
    "bg-white border border-white  font-medium text-gray-700 hover:bg-gray-50 disabled:text-brand-300 focus:ring-4 focus:ring-gray-100",
  };
   
  
  const Button = ({
    children,
    size = "big",
    color = "primary",
    disabled,
    loading,
    icon,
    className = "",
    transition = true, 
    href,
    to,
    ...props
  }) => { 
    
    if(href) {
      return (<a  href={href} className={`font-display  flex outline-none ${sizeClassnames[size]
      } ${transition ? `transition duration-200 ease-in-out` : ``} ${colorClassnames[color]
      } items-center justify-center ${className}`}
      {...props}>    <span className={loading ? "opacity-0" : `flex items-center`}>
      {icon ? <span className={`mr-1 items-center w-5 h-5`}>{icon}</span> : null}
      {children}
    </span></a>)
    }
    else if(to){
   
        return (<Link to={to} className={`font-display  flex outline-none ${sizeClassnames[size]
        } ${transition ? `transition duration-200 ease-in-out` : ``} ${colorClassnames[color]
        } items-center justify-center ${className}`}
        {...props}>    <span className={loading ? "opacity-0" : `flex items-center`}>
        {icon ? <span className={`mr-1 items-center w-5 h-5`}>{icon}</span> : null}
        {children}
      </span></Link>)
 
    }

    return (
 
      <button
      className={`font-display  flex outline-none ${sizeClassnames[size]
      } ${transition ? `transition duration-200 ease-in-out` : ``} ${colorClassnames[color]
      } items-center justify-center ${className}`}
      {...props}
      >

        <span className={loading ? "opacity-0" : `flex items-center`}>
          {icon ? <span className={`mr-1 items-center w-5 h-5`}>{icon}</span> : null}
          {children}
        </span>
   
        {loading ? (
          <span className={`absolute`}>
            <Spinner size={size === "small" ? "2" : "4"} />
          </span>
        ) : null}
      </button>
  
  )};

  export default Button