import React from 'react'
import {  Link } from 'gatsby'


export default function FooterAreasServed({data}){
 
    return (
        <div>
        <ul className="mt-2 leading-relaxed">
            {data.edges.map((item,i )=> (
                item.node.featuredCity ? 
                <li className="pb-1" key={i}>
                    <Link title={item.node.location + ' SEO Agency'} to={'/' + item.node.slug + (item.node.stateAbbreviation ? `-${item.node.stateAbbreviation.toLowerCase()}` : '') + ('-' + 'seo/')} className="text-base leading-6 text-gray-600 hover:text-gray-900">{item.node.location}, {item.node.stateAbbreviation}</Link>
                </li> : null
            ))}
        </ul>
        <Link  className="text-gray-600 hover:text-gray-900 mt-2 block" to={'/cities/'}>
          <i>View Full Service Area</i>
        </Link></div>
    )
}