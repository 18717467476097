import React, { useState, useEffect } from "react";
import { Link } from "gatsby"; 
import { Transition } from "@headlessui/react";
import MenuItemDropdown from "./MenuItemDropdown";
import WebPerfexLogo from '../components/logo'
import Button from "./atoms/Button";
import { ArrowRight } from "react-feather";

function Navbar({location, city, headerTransparent,headerNoBg, bottomBorder, navigation, mainnavigationstyle = "inline",companyname = "webperfex", slogan = "Slogan", subtagline="Subtagline", phonenumber}) {  


  const mainNavigationItems =
    navigation.allContentfulNavigation &&
    navigation.allContentfulNavigation.edges[0] &&
    navigation.allContentfulNavigation.edges[0].node.navItems


  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [contactFormOpen, setContactFormOpen] = useState(false);

  useEffect(() => {
    if(mobileMenuOpen) {
      document.body.classList.add('menu-expanded');
    }
 
    return function cleanup() {
      document.body.classList.remove('menu-expanded');
    };
   
  }, [mobileMenuOpen]);

  const MenuItem = ({ item, type = "pageitem", source, headerTransparent}) => {

    let itemId = item.id; 
    let slug = item.link; 
    let label = item.title

    const {isDropdown, title}= item

    //checks current path name which controls active selected items in the nav
    //location props required to be passed to layout component from the page to work

    let isActivePage = false
    if(location && location.pathname) {
       isActivePage = location && location.pathname.includes(slug)


       //check if current link exists in dropdown
       if(isDropdown) {
        isActivePage = item.subitems.filter(({ slug }) => location.pathname.includes(slug)).length > 0    
       }
    }
  

    switch (isDropdown) {
      case true:
        return <MenuItemDropdown isActivePage={isActivePage} item={item} label={label} type={1} source={source} headerTransparent={headerTransparent}/>;
      default:
        return (
          <Link
            to={`${slug && slug.length < 2 ? "" : slug }`}
            className={`${isActivePage ? 'border-brand-500 text-brand-600' : ''} ${source && source =='mobilemenu' ? 'block py-4 px-8 font-medium border-b border-gray-300' : `px-2 py-8 border-b-4 border-transparent ${headerTransparent ? 'text-white hover:border-brand-300 hover:text-brand-200' : 'text-gray-800 hover:text-brand-600  hover:border-brand-600 '}`}  font-semibold text-base   transition-colors duration-150 ease-in button-active-bhvr`}
          >
            {label}
          </Link>
        );
    }
  };
  return (
    <> 
    <header className={`${headerTransparent ? 'header--transparent-main' : ''} z-50`}>
      <div className={`bg-slate-800 text-white hidden md:block relative z-50`}>
        <div className="font-display mx-auto py-3 text-sm container  px-4 md:px-6">
        <span className="block text-center md:text-left">Top-Rated {city} Website Design & SEO Agency</span>
        </div>
      </div>
      <div className={`${headerTransparent || headerNoBg ? 'bg-white md:bg-transparent bg-transparent ' : 'bg-white shadow-sm '} ${bottomBorder && !headerTransparent ? 'border-b border-gray-100 md:shadow-none shadow-none': null} relative z-50`}>
        <div className="flex justify-between items-center px-4 md:px-6 py-6 lg:py-0 md:space-x-10 container mx-auto">
          <div>
          <Link to="/"  title={'WebPerfex'}>
              <div style={{ width: '180px' }}>
                <WebPerfexLogo logoClassOverride={'main-logo'}/>
              </div>
              <span className="md:hidden text-gray-700 font-display text-sm text-center block mt-2 font-semibold -mb-1">Web Design &amp; Digital Marketing</span>

            </Link>
          </div>

       

            {mainnavigationstyle == 'stacked' ?   
          <div className="hidden lg:block font-display text-center">
            <span className="font-semibold block">
              {slogan}
            </span>
            <span className="font-normal text-sm text-gray-800 block">
              {subtagline}
            </span>
          </div>  : null}




<div className="flex items-center">


{mainnavigationstyle == 'inline' ?   <div className="items-center font-display md:space-x-6 hidden lg:flex">
              {mainNavigationItems.map((item, i) => {
                return <MenuItem key={i} item={item} type={item.type} headerTransparent={headerTransparent} />;
              })}
            </div>  : null}

          <div class="hidden lg:ml-8 lg:flex lg:items-center lg:border-l lg:border-slate-900/15 lg:pl-8">
          <a className={`block font-bold text-base lg:text-lg  tracking-tight ${headerTransparent ? 'text-white' : 'text-brand-600'}`} href={`tel:${phonenumber}`}>{phonenumber} </a> 
            <Link to={'/contact-us/'}  onClick={() => setContactFormOpen(true)} className="inline-flex justify-center rounded-xl text-sm font-semibold py-2.5 px-4 bg-brand-600 text-white hover:bg-gray-700 -my-2.5 ml-8">
              <span className="flex items-center space-x-2">Start your project <span aria-hidden="true"><ArrowRight className="ml-1 w-4 h-4"/></span></span></Link></div>
            
          {/* <div className="hidden md:block font-display">
            <div className="flex flex-row lg:flex-col xl:flex-row items-center">
              <div className="pr-8 lg:pr-0 xl:pr-8 text-center">
                <p className="mb-0 text-gray-700 leading-5 text-sm xl:text-base hidden">Give Us A Call Today</p>
                <a className={`block font-bold text-lg md:text-xl lg:text-lg xl:text-2xl tracking-tight ${headerTransparent ? 'text-white' : 'text-brand-600'}`} href={`tel:${phonenumber}`}>{phonenumber} </a> 
 
              </div>
              
            </div>
          </div> */}
</div>
          <div className={`-mr-2 -my-2 ${mainnavigationstyle == 'stacked' ? 'md:hidden'  : 'lg:hidden'}`}>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="text-center p-2 rounded-lg text-gray-600 hover:bg-gray-200  focus:text-gray-700  focus:bg-gray-50  focus:outline-none  transition duration-150 ease-in"
            >{mobileMenuOpen ? <svg
              className="h-6 w-6 mx-auto"
              x-description="Heroicon name: x"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg> :  <svg
                className="h-6 w-6 mx-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>}
             
              <span className="block uppercase font-semibold" style={{fontSize:'.5rem'}}>{mobileMenuOpen ? 'Close' : 'Menu'}</span>
            </button>
          </div>
        
        </div>

        <Transition
            show={mobileMenuOpen}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            className={`absolute inset-x-0 border-t  border-gray-300 transition transform origin-top-right z-50 ${mainnavigationstyle == 'stacked' ? 'md:hidden'  : 'lg:hidden'}`} 
            style={{top:'100%'}}
          >
            <div className=" shadow-lg font-display">
              <div className="shadow-xs bg-white">
               
                <div>
                {mainNavigationItems.map((item, i) => {
                  return <MenuItem key={i} item={item} type={item.type} source={'mobilemenu'}/>;
                })}
                  </div>
              </div>
            </div>
          </Transition>
      </div>
        

      {mainnavigationstyle == 'stacked' ?  <div className="relative bg-brand-500 text-white md:shadow-sm md:border-none z-10 hidden md:block font-display">
        <div className="flex items-center px-4 md:px-6 md:space-x-10 container mx-auto ">
          {mainNavigationItems.map((item, i) => {
            return <MenuItem key={i} item={item} type={item.type} />;
          })}
        </div>
      </div>  : null}
     
    </header>

    <div className="sticky top-0 bg-brand-500  text-brand-900  shadow-sm md:border-none z-30 md:hidden">
    <div className="flex  items-center justify-between px-4 md:px-6  py-3 container mx-auto ">
    <div>
        <p className="mb-0 text-white font-display hidden sm:block">Give Us A Call Today</p>
        <a className="block font-semibold text-xl font-display text-brand-100" href={`tel:${phonenumber}`}>{phonenumber}</a> 
 
      </div>
      <Button to={'/contact-us/'} size="xl" color="light">Contact Us</Button>


    {/* <button
      onClick={() => setContactFormOpen(true)}
      className="button-active-bhvr block cursor-pointer transition duration-150 ease-in-out focus:outline-none font-semibold px-4 py-2 lg:py-3 lg:px-5 rounded-full text-brand-600 bg-white font-display"
    >
      Free Estimate
    </button> */}
      </div>
  
    </div>
  </>
  );
}

export default Navbar;
