import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Navbar from './Navbar'
import '../styles/imports.css'
// import '../fonts/fonts.css'
import Footer from './footer'

const Layout = ({
  city = 'Sacramento',
  children,
  backgroundGrey,
  isServicePage,
  location,
  ...props
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            companyInfo {
              phone
              email
            }
            socials {
              facebook
              instagram
              dribble
              google
            }
          }
        }
        allContentfulNavigation(
          filter: { contentful_id: { eq: "34gTF8jxgyw5No5lt9MSHO" } }
        ) {
          edges {
            node {
              id
              navItems {
                id
                title
                link
                isDropdown
                svgIcon {
                  id
                  svgIcon
                }
                subitems {
                  ... on ContentfulService {
                    id
                    title
                    slug
                    metaDescription
                    areasServiceService { 
                      slug
                    }
                    areasServiceMenuItems {
                        location
                        stateAbbreviation 
                        slug
                    }
                  }
                  ... on ContentfulServiceAreaSubmenuItem {
                    id
                  }
                }
                dropdownShowItemDescriptions
                dropdownShowIcons
                dropdownColumnsCount
              }
            }
          }
        }
      }
    `}



    render={data => { 
      
      let headerNoBg = false

      //if homepage, transparent header
      if(location && location.pathname && location.pathname === "/") headerNoBg = true

      return (
        <>
          <Navbar
            location={location}
            city={city}
            siteTitle={data.site.siteMetadata.title}
            phonenumber={data.site.siteMetadata.companyInfo.phone}
            navigation={data}
            isServicePage={isServicePage ? isServicePage : false}
            headerNoBg={headerNoBg}
          />
           <div className={`${backgroundGrey ? 'bg-gray-50' : ''}`}>
            {children}{' '}
          </div>
          <Footer siteMetadata={data.site.siteMetadata} city={city} />
        </>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
